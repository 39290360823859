
.card-coupon {
    overflow: hidden;
    &.v-card--disabled {
        pointer-events: auto;
        user-select: auto;
        cursor: pointer;
        opacity: 0.3;
        & > :not(.v-card__progress) {
            opacity: 1;
        }
        &.theme--light {
            &.v-card {
                color: var(--default-color);
            }
            &.v-sheet--outlined {
                border: 1px solid var(--border-color);
            }
        }
    }
    &__left {
        height: 100%;
        overflow: hidden;
        &-inner {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 20px 30px;
        }
    }
    &__right {
        height: 100%;
        width: 60px;
        &-inner {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
            padding: 12px;
            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                z-index: 1;
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                border: 1px solid var(--border-color);
                background-color: #fff;
            }
            &::before {
                top: 0;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
            &::after {
                bottom: 0;
                -webkit-transform: translate(-50%, 50%);
                -ms-transform: translate(-50%, 50%);
                transform: translate(-50%, 50%);
            }
        }
        &-txt {
            display: flex;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.5em;
            font-family: var(--font-secondary);
            color: #fff;
            transform: rotate(90deg);
            margin-bottom: -0.5em;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .card-coupon {
        &__right {
            width: 80px;
        }
    }
}
@media (min-width: 1024px) {
    .card-coupon {
        &__left {
            &-inner {
                padding: 30px 40px;
            }
        }
    }
}
@media (min-width: 1200px) {
}
